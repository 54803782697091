import React, { useContext } from "react"
import { graphql } from "gatsby"
import PortableText from "../components/PortableText"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { I18nextContext } from "gatsby-plugin-react-i18next"
import ImageComponent from "../components/ImageComponent"
import HeaderName from "../components/HeaderName"
import Catalogues from "../components/Catalogues"
import { GatsbyImage } from "gatsby-plugin-image"

const IndexPage = ({ data }) => {
  const { title, thumbnailImage, _rawText, adBlock } = data.sanityMainPage
  const { language } = useContext(I18nextContext)
  return (
    <Layout>
      <Seo lang={language} title={title[language]} />
      <div className="grid grid-cols-6 min-h-screen">
        <div className="col-span-6 sm:col-span-2 bg-gradient-to-b from-gray-700 to-gray-800">
          {thumbnailImage.map((image, index) => (
            <ImageComponent
              key={index}
              imageData={
                image.mainImage.asset.localFile.childImageSharp.gatsbyImageData
              }
              imageAlt={image.title[language]}
              imageName={image.title[language]}
              imageParameters={image.parameters[language]}
            />
          ))}
          <Catalogues />
        </div>
        <div className="relative col-span-6 sm:col-span-4 bg-gray-100 font-raleway">
          <HeaderName headerName={title[language]} />
          <div className="p-5 lg:px-10">
            <PortableText textData={_rawText[language]} />
          </div>
          {/* ads block */}
          <div className="m-5 lg:m-10">
            <a href={adBlock.url} target="_blank" rel="noopener noreferrer">
              <div className="relative bg-gray-800 h-60 rounded">
                <GatsbyImage
                  image={
                    adBlock.photo.asset.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  className="categories-pics h-full"
                  alt={"Damir Krivenko"}
                  width={400}
                />

                <div className="absolute h-full w-full xl:w-1/2 top-0 right-0 bg-white bg-opacity-70">
                  <div className="p-5 lg:p-10 text-lg lg:text-2xl">
                    <PortableText textData={adBlock._rawAdsText[language]} />
                  </div>
                </div>
                <div className="absolute rounded h-20 w-full bottom-0 right-0 bg-gray-800 bg-opacity-90">
                  <div className="pl-5 lg:pl-10 text-3xl font-raleway text-white">
                    {adBlock.title[language]}
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    sanityMainPage {
      title {
        en
        ru
      }
      thumbnailImage {
        title {
          en
          ru
        }
        parameters {
          en
          ru
        }
        shortDescription {
          ru
          en
        }
        mainImage {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, width: 600)
              }
            }
          }
        }
      }
      _rawText(resolveReferences: { maxDepth: 10 })
      adBlock {
        _rawAdsText(resolveReferences: { maxDepth: 10 })
        url
        title {
          ru
          en
        }
        photo {
          asset {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, width: 1000)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
